import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { AuthContext } from "@/context/AuthProvider";
import { UserRoles } from "@/types/user";
import { Link } from "gatsby";
import { LogIn, LogOut, User } from "lucide-react";
import React, { useContext } from "react";
import { NavProps } from "../header";
import "@/styles/components/nav-desktop.css";

const userMenuItemClasses =
  "flex items-center p-2 pl-3 hover:bg-neutral-100 w-full";

export default function NavDesktop({ apps, userMenuItems, logout }: NavProps) {
  const { user, setUser, userLoading } = useContext(AuthContext);

  return (
    <>
      {!userLoading && (
        <div className="desktop-nav flex w-full">
          <NavigationMenu className="h-full flex">
            <NavigationMenuList className="h-full flex">
              {/* Nav Links */}
              {apps.map((app, index) => {
                if (
                  app.isActive &&
                  (!app.roles ||
                    app.roles.length === 0 ||
                    (user &&
                      user.data &&
                      (user.data.roles.includes(UserRoles.ADMIN) ||
                        user.data.roles.some((role) =>
                          app.roles?.includes(role)
                        ))))
                ) {
                  return (
                    <NavigationMenuItem
                      className="nav-desktop-item"
                      key={"nav-desktop-link-" + index}
                    >
                      <Link
                        to={"/" + app.link!}
                        className="flex items-center h-full hover:bg-primary-dark pl-4 pr-4 m-0 text-neutral-50 font-normal text-lg"
                        activeClassName="bg-primary-dark"
                      >
                        {app.displayName}
                      </Link>
                    </NavigationMenuItem>
                  );
                }
              })}
            </NavigationMenuList>
          </NavigationMenu>

          {/* User Menu */}
          {user && user.data ? (
            <div className="h-full hover:bg-primary-dark flex items-center text-neutral-50 hover:cursor-pointer">
              <Popover>
                <PopoverTrigger asChild>
                  <div className="w-full h-full flex justify-center items-center pl-4 pr-4">
                    <User size={38} strokeWidth={1.8} />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-fit min-w-24 p-0">
                  <h4 className={userMenuItemClasses + " mt-0 rounded-t-md"}>
                    Hallo {user.data.firstname}!
                  </h4>
                  <ul className="h-full w-full flex flex-col">
                    {userMenuItems.map((menuItem, index) => {
                      return (
                        <li key={"user-menu-item-" + index} className="flex">
                          <Link
                            to={menuItem.location}
                            className={userMenuItemClasses}
                          >
                            {menuItem.title}
                          </Link>
                        </li>
                      );
                    })}

                    {/* Adminbereich */}
                    {user.data.roles.includes(UserRoles.ADMIN) && (
                      <li className="flex">
                        <Link to="/admin" className={userMenuItemClasses}>
                          Adminbereich
                        </Link>
                      </li>
                    )}

                    {/* Logout */}
                    <li className="flex">
                      <span
                        className={
                          userMenuItemClasses +
                          " rounded-b-md hover:cursor-pointer"
                        }
                        onClick={() => logout()}
                      >
                        Abmelden
                        <LogOut size={20} className="ml-2" />
                      </span>
                    </li>
                  </ul>
                </PopoverContent>
              </Popover>
            </div>
          ) : (
            <Link
              to="/login"
              className="h-full hover:bg-primary-dark pl-4 pr-4 flex items-center text-neutral-50"
            >
              <LogIn size={38} />
            </Link>
          )}
        </div>
      )}
    </>
  );
}
