exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actionpoints-tsx": () => import("./../../../src/pages/actionpoints.tsx" /* webpackChunkName: "component---src-pages-actionpoints-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-firebase-callback-tsx": () => import("./../../../src/pages/firebase-callback.tsx" /* webpackChunkName: "component---src-pages-firebase-callback-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-geschenkideen-add-tsx": () => import("./../../../src/pages/geschenkideen/add.tsx" /* webpackChunkName: "component---src-pages-geschenkideen-add-tsx" */),
  "component---src-pages-geschenkideen-edit-list-id-tsx": () => import("./../../../src/pages/geschenkideen/edit/[listId].tsx" /* webpackChunkName: "component---src-pages-geschenkideen-edit-list-id-tsx" */),
  "component---src-pages-geschenkideen-index-tsx": () => import("./../../../src/pages/geschenkideen/index.tsx" /* webpackChunkName: "component---src-pages-geschenkideen-index-tsx" */),
  "component---src-pages-geschenkideen-list-id-tsx": () => import("./../../../src/pages/geschenkideen/[listId].tsx" /* webpackChunkName: "component---src-pages-geschenkideen-list-id-tsx" */),
  "component---src-pages-import-tsx": () => import("./../../../src/pages/import.tsx" /* webpackChunkName: "component---src-pages-import-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kochbuch-add-tsx": () => import("./../../../src/pages/kochbuch/add.tsx" /* webpackChunkName: "component---src-pages-kochbuch-add-tsx" */),
  "component---src-pages-kochbuch-edit-recipe-id-tsx": () => import("./../../../src/pages/kochbuch/edit/[recipeId].tsx" /* webpackChunkName: "component---src-pages-kochbuch-edit-recipe-id-tsx" */),
  "component---src-pages-kochbuch-index-tsx": () => import("./../../../src/pages/kochbuch/index.tsx" /* webpackChunkName: "component---src-pages-kochbuch-index-tsx" */),
  "component---src-pages-kochbuch-recipe-id-tsx": () => import("./../../../src/pages/kochbuch/[recipeId].tsx" /* webpackChunkName: "component---src-pages-kochbuch-recipe-id-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-profil-tsx": () => import("./../../../src/pages/profil.tsx" /* webpackChunkName: "component---src-pages-profil-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-ticketsales-tsx": () => import("./../../../src/pages/ticketsales.tsx" /* webpackChunkName: "component---src-pages-ticketsales-tsx" */),
  "component---src-pages-zitate-tsx": () => import("./../../../src/pages/zitate.tsx" /* webpackChunkName: "component---src-pages-zitate-tsx" */),
  "component---src-templates-simple-tsx": () => import("./../../../src/templates/simple.tsx" /* webpackChunkName: "component---src-templates-simple-tsx" */)
}

