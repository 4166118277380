import "./src/styles/globals.css";
import "firebase/auth";
import "firebase/firestore";
import React from "react";
import AuthProvider from "./src/context/AuthProvider";
import RolesProvider from "./src/context/firestore/RolesProvider";
import ReactDOM from "react-dom/client";
import Layout from "./src/components/layout/layout.tsx";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const wrapRootElement = ({ element }) => (
  <RolesProvider>
    <AuthProvider>{element}</AuthProvider>
  </RolesProvider>
);
