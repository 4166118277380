import { db } from "@/services/firebase";
import { UserRoles } from "@/types/user";
import {
  DocumentReference,
  DocumentData,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

interface RolesProviderProps {
  children?: React.ReactNode;
}

interface TRolesContext {
  roles: Map<UserRoles, DocumentReference<DocumentData, DocumentData>>;
  setRoles:
    | React.Dispatch<
        React.SetStateAction<
          Map<UserRoles, DocumentReference<DocumentData, DocumentData>>
        >
      >
    | undefined;
  rolesLoading: boolean;
}

export const RolesContext = createContext<TRolesContext>({
  roles: new Map(),
  setRoles: undefined,
  rolesLoading: true,
});

export default function RolesProvider({ children }: RolesProviderProps) {
  const [rolesDocs, rolesDocsLoading] = useCollection(
    query(collection(db, "roles"), orderBy("role", "desc"))
  );
  const [roles, setRoles] = useState<
    Map<UserRoles, DocumentReference<DocumentData, DocumentData>>
  >(new Map<UserRoles, DocumentReference<DocumentData, DocumentData>>());
  const [rolesLoading, setRolesLoading] = useState(true);

  useEffect(() => {
    if (!rolesDocsLoading) {
      if (rolesDocs) {
        rolesDocs.forEach((rolesDoc) => {
          var userRole = rolesDoc.data().role as UserRoles;
          roles.set(userRole, rolesDoc.ref);
        });
        setRolesLoading(false);
      }
      setRolesLoading(false);
    }
  }, [rolesDocsLoading]);

  return (
    <RolesContext.Provider value={{ roles, setRoles, rolesLoading }}>
      {children}
    </RolesContext.Provider>
  );
}
