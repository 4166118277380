import { useRoles } from "@/hooks/firestore/use-roles";
import { auth, db } from "@/services/firebase";
import { User, UserData, UserRoles } from "@/types/user";
import {
  DocumentData,
  DocumentReference,
  Unsubscribe,
  doc,
  onSnapshot,
} from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export interface IAuthContext {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>> | undefined;
  userLoading: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export default function AuthProvider({ children }: any) {
  const [authUser, authUserLoading] = useAuthState(auth);
  const [roles, setRoles, rolesLoading] = useRoles();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    let userDataUnsubscribe: Unsubscribe;

    if (!rolesLoading) {
      if (!authUserLoading) {
        if (authUser) {
          var tmpUser: User = {
            auth: authUser,
            data: undefined,
          };

          userDataUnsubscribe = onSnapshot(
            doc(db, "users", authUser.uid),
            { includeMetadataChanges: true },
            (userDoc) => {
              if (userDoc) {
                const tmpUserDataRaw = userDoc.data();
                if (tmpUserDataRaw) {
                  var tmpRoles: UserRoles[] = [];
                  roles.forEach(
                    (
                      value: DocumentReference<DocumentData, DocumentData>,
                      key: UserRoles
                    ) => {
                      tmpUserDataRaw.roles.forEach((role: any) => {
                        if (role.id === value.id) {
                          tmpRoles.push(key);
                        }
                      });
                    }
                  );

                  var tmpUserData: UserData = {
                    uid: authUser.uid,
                    roles: tmpRoles,
                    firstname: tmpUserDataRaw.firstname,
                    lastname: tmpUserDataRaw.lastname,
                    email: tmpUserDataRaw.email,
                    birthday: new Date(tmpUserDataRaw.birthday.toDate()),
                  };
                  if (tmpUserDataRaw.created) {
                    tmpUserDataRaw.created = new Date(
                      tmpUserDataRaw.created.toDate()
                    );
                  }
                  tmpUser.data = tmpUserData;

                  setUser({ ...tmpUser });
                  setUserLoading(false);
                }
              } else {
                setUser({ ...tmpUser });
                setUserLoading(false);
              }
            }
          );
        } else {
          setUser(undefined);
          setUserLoading(false);
        }
      }
    }
    return () => {
      if (userDataUnsubscribe) {
        userDataUnsubscribe();
      }
    };
  }, [rolesLoading, authUser]);

  return (
    <AuthContext.Provider value={{ user, setUser, userLoading }}>
      {children}
    </AuthContext.Provider>
  );
}
