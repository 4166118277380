import { User as FirebaseUser } from "firebase/auth";

export enum UserRoles {
  ADMIN = "Admin",
  FRIEND = "Friend",
  FAMILY = "Family",
  STAGE = "Stage",
}

export interface UserData {
  uid?: string;
  roles: UserRoles[];
  firstname: string;
  lastname: string;
  email: string;
  birthday: Date | undefined;
  created?: Date
}

export interface User {
  auth: FirebaseUser | undefined;
  data: UserData | undefined;
}
