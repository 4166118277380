import { Link } from "gatsby";
import React from "react";
import { useCookies } from "react-cookie";

interface FooterLink {
  title: string;
  location: string;
}

export default function Footer() {
  const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);

  const footerLinks: FooterLink[] = [
    { title: "Kontakt", location: "/kontakt" },
    { title: "Impressum", location: "/impressum" },
    { title: "Datenschutz", location: "/datenschutz" },
  ];

  const footerPadding =
    " pl-48 pr-48 max-2xl:pl-32 max-2xl:pr-32 max-xl:pl-24 max-xl:pr-24 max-lg:pl-8 max-lg:pr-8 max-sm:pl-4 max-sm:pr-4";

  return (
    <div className="flex flex-col">
      <div
        className={"bg-primary flex justify-center pt-4 pb-4" + footerPadding}
      >
        <ul className="list-none flex flex-col justify-center items-center text-neutral-50">
          {footerLinks.map((link, index) => {
            return (
              <li key={"footer-link-" + index}>
                <Link to={link.location} className="hover:underline">
                  {link.title}
                </Link>
              </li>
            );
          })}
          <li>
            <p
              className="hover:underline hover:cursor-pointer m-0"
              onClick={() => removeCookie("cookieConsent", { path: "/" })}
            >
              Cookies verwalten
            </p>
          </li>
        </ul>
      </div>
      <div
        className={
          "bg-primary-dark flex justify-center items-center pt-2 pb-2 text-neutral-50" +
          footerPadding
        }
      >
        &copy; {new Date().getFullYear()} Nick Fischer
      </div>
    </div>
  );
}
