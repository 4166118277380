import React, { useContext, useEffect, useState } from "react";
import {
  SheetTrigger,
  SheetContent,
  Sheet,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

import { NavProps } from "../header";
import { Link } from "gatsby";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { AuthContext } from "@/context/AuthProvider";
import { UserRoles } from "@/types/user";
import { AlignJustify, LogIn, LogOut } from "lucide-react";
import "@/styles/components/nav-mobile.css";

const userMenuItemClasses =
  "flex items-center hover:bg-primary-dark text-neutral-50 text-lg pt-4 pb-4 pl-8 pr-8";

export default function NavMobile({ apps, userMenuItems, logout }: NavProps) {
  const { user, setUser, userLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!userLoading) {
      setLoading(false);
    }
  }, [userLoading]);

  const mobileLogout = () => {
    logout();
    setOpen(false);
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger className="ml-auto hover:bg-primary-dark pl-2 pr-2">
        <AlignJustify
          size={48}
          strokeWidth={1.6}
          className="stroke-neutral-50"
        />
      </SheetTrigger>
      <SheetContent className="mobile-nav bg-primary border-none w-fit p-0">
        <SheetHeader>
          <VisuallyHidden>
            <SheetTitle>Mobiles Navigationsmenü</SheetTitle>
          </VisuallyHidden>
        </SheetHeader>
        <ul className="list-none flex flex-col h-full">
          {/* Nav Links */}
          {apps.map((app, index) => {
            if (
              app.isActive &&
              (!app.roles ||
                app.roles.length === 0 ||
                (user &&
                  user.data &&
                  (user.data.roles.includes(UserRoles.ADMIN) ||
                    user.data.roles.some((role) => app.roles?.includes(role)))))
            ) {
              return (
                <Link
                  to={"/" + app.link!}
                  className={userMenuItemClasses}
                  activeClassName="bg-primary-dark"
                  key={"nav-mobile-link-" + index}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <li>{app.displayName}</li>
                </Link>
              );
            }
          })}

          {/* User Menu */}
          <div className="mt-auto">
            {user && user.auth && user.data ? (
              <>
                <h4 className="flex items-center text-neutral-50 text-lg pt-4 pb-4 pl-8 pr-8">
                  Hallo {user.data.firstname}!
                </h4>
                {userMenuItems.map((menuItem, index) => {
                  return (
                    <Link
                      to={menuItem.location}
                      className={userMenuItemClasses}
                      key={"mobile-user-menu-item-" + index}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {menuItem.title}
                    </Link>
                  );
                })}

                {/* Adminbereich */}
                {user.data.roles.includes(UserRoles.ADMIN) && (
                  <Link
                    to="/admin"
                    className={userMenuItemClasses}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Adminbereich
                  </Link>
                )}

                {/* Logout */}
                <div
                  className={userMenuItemClasses + " hover:cursor-pointer"}
                  onClick={() => mobileLogout()}
                >
                  Ausloggen
                  <LogOut size={20} className="ml-2" />
                </div>
              </>
            ) : (
              <Link
                to="/login"
                className={userMenuItemClasses}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Einloggen
                <LogIn size={20} className="ml-2" />
              </Link>
            )}
          </div>
        </ul>
      </SheetContent>
    </Sheet>
  );
}
