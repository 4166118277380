import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "@/styles/components/cookies.css";

export const CookieCheckboxes: CheckboxData[] = [
  {
    name: "hCaptcha",
    description: "hCaptcha (Bot-Erkennung)",
  },
];

interface CheckboxData {
  name: string;
  description: string;
}

export default function Cookies() {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  var defaultCheckboxValues = {};
  CookieCheckboxes.forEach((checkbox) => {
    defaultCheckboxValues = {
      ...defaultCheckboxValues,
      [checkbox.name]: false,
    };
  });
  var allTrueCheckboxValues = {};
  CookieCheckboxes.forEach((checkbox) => {
    allTrueCheckboxValues = {
      ...allTrueCheckboxValues,
      [checkbox.name]: true,
    };
  });

  const [checkboxValues, setCheckboxValues] = useState<{
    [key: string]: string;
  }>(defaultCheckboxValues);

  const handleCheckboxChange = (e: any) => {
    setCheckboxValues({
      ...checkboxValues,
      [e.target.id]: !checkboxValues[e.target.id as string],
    });
  };

  const handleSetCookies = (values: any) => {
    var now = new Date();
    setCookie("cookieConsent", values, {
      path: "/",
      expires: new Date(now.setMonth(now.getMonth() + 6)),
    });
  };

  const btnClasses =
    "bg-primary hover:bg-primary-dark text-neutral-50 font-semibold flex items-center max-w-full w-full max-sm:text-xs max-sm:p-2 max-sm:font-medium max-sm:h-fit";

  return (
    <Dialog defaultOpen={true} open={true}>
      <DialogContent className="cookie-dialog max-w-2xl max-h-full max-lg:max-w-lg max-sm:max-w-[90%] max-sm:p-4 overflow-y-scroll max-sm:pb-8">
        <DialogHeader>
          <DialogTitle className="text-2xl mt-0 max-sm:text-lg">
            Wir nutzen Cookies
          </DialogTitle>
          <DialogDescription className="text-sm max-sm:text-xs max-h-64 overflow-y-scroll">
            Wir verwenden Cookies und ähnliche Technologien auf unserer Website
            und verarbeiten personenbezogene Daten von dir (z.B. IP-Adresse), um
            z.B. Inhalte und Anzeigen zu personalisieren, Medien von
            Drittanbietern einzubinden oder Zugriffe auf unsere Website zu
            analysieren.
            <br />
            <br />
            Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies
            stattfinden. Wir teilen diese Daten mit Dritten, die wir in den
            Privatsphäre-Einstellungen benennen. Die Datenverarbeitung kann mit
            deiner Einwilligung oder auf Basis eines berechtigten Interesses
            erfolgen, dem du in den Privatsphäre-Einstellungen widersprechen
            kannst. Bei Ablehnung ist es möglich, dass einige Funktionen nicht
            zur Verfügung stehen. Weitere Informationen zur Verwendung deiner
            Daten findest du in unserer&nbsp;
            <a
              href="/datenschutz#datenerfassung"
              target="_blank"
              className="underline"
            >
              Datenschutzerklärung
            </a>
            .
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex max-sm:flex-col max-sm:gap-2">
          <div className="flex flex-col w-full">
            <div className="flex -mt-2 mb-3 gap-4 max-sm:flex-col">
              {CookieCheckboxes.map((checkbox, index) => {
                return (
                  <div
                    className="flex justify-center items-center"
                    key={"cookie-checkbox-wrapper" + index}
                  >
                    <Checkbox
                      id={checkbox.name}
                      onClick={(e) => handleCheckboxChange(e)}
                      className="data-[state=checked]:text-neutral-50"
                      key={"cookie-checkbox-" + index}
                    />
                    <label
                      htmlFor={checkbox.name}
                      className="text-md font-medium ml-2 hover:cursor-pointer max-sm:text-sm"
                    >
                      {checkbox.description}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="flex gap-2 justify-end max-sm:flex-col">
              <Button
                className={btnClasses}
                onClick={() => handleSetCookies(defaultCheckboxValues)}
                aria-label="Alle Cookies ablehnen"
              >
                Alle ablehnen
              </Button>
              <Button
                className={btnClasses}
                onClick={() => handleSetCookies(checkboxValues)}
                aria-label="Ausgewählte Cookies akzeptieren"
              >
                Auswahl akzeptieren
              </Button>
              <Button
                className={btnClasses}
                onClick={() => handleSetCookies(allTrueCheckboxValues)}
                aria-label="Alle Cookies akzeptieren"
              >
                Alle akzeptieren
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
