import React from "react";
import BarLoader from "react-spinners/BarLoader";

interface LoadingBarProps {
  width?: string;
  height?: string;
  color?: string;
}

export default function LoadingBar({ width, height, color }: LoadingBarProps) {
  return (
    <BarLoader
      width={width ? width : "100%"}
      height={height ? height : 4}
      color={color ? color : "#2962ff"}
    />
  );
}
