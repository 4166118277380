import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { Toaster } from "@/components/ui/toaster";
import { useCookies } from "react-cookie";
import Cookies from "../cookies";
import { ChevronUp } from "lucide-react";
import LoadingBar from "../custom-ui/loading-bar";

interface LayoutProps {
  loading?: boolean;
  children?: React.ReactNode;
}

export const excludeCookieConsentSites: string[] = [
  "/datenschutz/",
  "/impressum/",
];

export default function Layout({ loading, children }: LayoutProps) {
  const [showToTop, setShowToTop] = useState(false);
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    if (!excludeCookieConsentSites.includes(window.location.pathname)) {
      setShowCookieConsent(true);
    }

    window.onscroll = () => {
      if (window.scrollY > 200) {
        setShowToTop(true);
      } else {
        setShowToTop(false);
      }
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="layout-container h-full m-0 flex flex-col">
      <div className="flex-grow shrink-0 basis-auto flex items-center flex-col">
        <header className="w-full">
          <Header></Header>
        </header>
        <main className="mb-auto bg-neutral-100 h-full w-full flex flex-col items-center content-center pl-48 pr-48 pt-4 pb-4 max-2xl:pl-32 max-2xl:pr-32 max-xl:pl-24 max-xl:pr-24 max-lg:pl-8 max-lg:pr-8 max-sm:pl-4 max-sm:pr-4">
          <div className="h-full w-full">
            {loading ? <LoadingBar /> : children}
          </div>
        </main>
        <footer className="shrink-0 w-full">
          <Footer></Footer>
        </footer>
      </div>

      {/* Scroll to top */}
      <div
        className={
          "to-top bg-neutral-300 text-neutral-900 hover:bg-neutral-400 hover:cursor-pointer fixed bottom-12 right-12 z-40 w-10 h-10 p-1 rounded-md items-center content-center text-center" +
          (showToTop ? " flex" : " hidden")
        }
        id="to-top"
        onClick={scrollToTop}
      >
        <ChevronUp size={32} />
      </div>

      <Toaster />

      {showCookieConsent && !cookies.cookieConsent && <Cookies />}
    </div>
  );
}
